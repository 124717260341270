.grid-container {
  margin: 0;
  border: none;
  padding: 0;
}

.background-image {
  position: absolute;
  z-index: 0;
  display: none;
}

.grid-canvas {
  position: absolute;
  z-index: 1;
}